<template>
    <Layout>
        <h4>Reference Number : {{ details.ref_no }}</h4>
        <b-overlay :show="show" rounded="sm">
            <div class="row">
                <div class="col-lg-6">
                    <b-tabs
                        active-nav-item-class="font-weight-bold text-uppercase text-primary"
                        active-tab-class="font-weight-bold "
                        content-class="mt-3"
                    >
                        <b-tab title="Contact Information">
                            <div class="contact_info">
                                <p>
                                    <span class="title">Customer Name :</span>
                                    &nbsp;&nbsp;{{ details.customer_name }}
                                </p>
                                <p>
                                    <span class="title">Unit Number :</span>
                                    &nbsp;&nbsp;{{ details.unit_no }}
                                </p>
                                <p>
                                    <span class="title"> Type :</span>
                                    &nbsp;&nbsp;{{ details.visitor_type }}
                                </p>
                                <p>
                                    <span class="title">Subject :</span>
                                    &nbsp;&nbsp;{{ details.subject }}
                                </p>
                                <p>
                                    <span class="title">
                                        Concerned Person:</span
                                    >
                                    &nbsp;&nbsp;{{ details.concerned_person }}
                                </p>
                                <p>
                                    <span class="title"> Attended By :</span>
                                    &nbsp;&nbsp;{{ details.attended_by }}
                                </p>
                                <p>
                                    <span class="title"> Phone Number :</span>
                                    &nbsp;&nbsp;{{ details.phone_no }}
                                </p>
                                <p>
                                    <span class="title"> Mobile Number :</span>
                                    &nbsp;&nbsp;{{ details.mobile_no }}
                                </p>
                                <p>
                                    <span class="title"> Email :</span>
                                    &nbsp;&nbsp;{{ details.email }}
                                </p>
                                <p>
                                    <span class="title">
                                        Mailing Address :</span
                                    >
                                    &nbsp;&nbsp;{{ details.mailing_address }}
                                </p>
                                <p>
                                    <span class="title">
                                        Physical Address :</span
                                    >
                                    &nbsp;&nbsp;{{ details.physical_address }}
                                </p>
                                <p>
                                    <span class="title"> Time :</span>
                                    &nbsp;&nbsp;{{ details.created_at }}
                                </p>
                                <p>
                                    <span class="title"> Department :</span>
                                    &nbsp;&nbsp;{{ details.department }}
                                </p>
                                <p>
                                    <span class="title"> Via :</span>
                                    &nbsp;&nbsp;{{ details.via }}
                                </p>
                                <p>
                                    <span class="title"> Company Name :</span>
                                    &nbsp;&nbsp;{{ details.company }}
                                </p>
                                <p>
                                    <span class="title"> Status :</span>
                                    <span v-for="stat in stats" :key="stat.id">
                                        <b-badge
                                            class="badge"
                                            variant="dark"
                                            v-if="stat.id == details.status"
                                        >
                                            {{ stat.text }}</b-badge
                                        >
                                    </span>
                                </p>
                            </div>
                        </b-tab>

                        <b-tab title="Documents">
                            <p>
                                <span class="title">
                                    Customer Comments & Recommendations :</span
                                >
                                &nbsp;&nbsp;{{ details.customer_comments }}
                            </p>
                            <p>
                                Document&nbsp;&nbsp;:&nbsp;&nbsp;

                                <b-button
                                    @click="viewDocument(details.document)"
                                    variant="primary"
                                >
                                    View
                                </b-button>
                            </p>
                        </b-tab>
                    </b-tabs>
                </div>

                <div class="col-lg-6">
                    <div
                        v-if="
                            userRole == 'Front Desk' ||
                            userRole == 'Administrator'
                        "
                    >
                        <h2>Actions</h2>

                        <b-button
                            variant="primary"
                            @click="
                                updateRequestStatus(
                                    details.id,
                                    3,
                                    requestType,
                                    'Close this request'
                                )
                            "
                            >Close Request</b-button
                        >
                    </div>

                    <br />
                    <addComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />

                    <addRejectComment
                        :details="details"
                        :requestType="requestType"
                        @refreshContent="refetchItem"
                    />

                    <workFlowDiagram
                        :workAssigned="workAssigned"
                        :details="details"
                    />
                </div>
            </div>
        </b-overlay>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import { BASE_URL, REQUEST_STATUS } from "../../common";
import axios from "axios";
import unitMixin from "../../../mixins/unitMixin";
import addComment from "../requestFlow/addComments.vue";
import addRejectComment from "../requestFlow/addRejectionComments.vue";
import workFlowDiagram from "../requestFlow/workFlow.vue";
export default {
    mixins: [unitMixin],
    components: {
        Layout,
        addComment,
        addRejectComment,
        workFlowDiagram,
    },
    mounted() {
        if (localStorage.cs_user_token) {
            this.userRole = localStorage.cs_user_role;
            this.fetchDetails(this.$route.params.id);
        }
    },
    data() {
        return {
            requestType: null,
            stats: REQUEST_STATUS,
            userRole: null,
            show: true,
            details: {},
            workAssigned: [],
        };
    },
    methods: {
        refetchItem() {
            this.fetchDetails(this.$route.params.id);
        },

        fetchDetails(id) {
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}visitors/${id}/?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        console.log(res.data);
                        this.show = false;
                        this.requestType = res.data.request_type;
                        this.details = res.data.response;
                        this.workAssigned = res.data.workassigned;
                    });
            }
        },
    },
};
</script>
<style scoped>
.title {
    font-weight: bolder;
}
p {
    color: black;
}

.dot {
    border-radius: 50%;
    height: 3px;
    width: 3px;
    background: linear-gradient(90deg, black, black);
    margin: 3px 0;
}
.dot-container {
    width: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}
.badge {
    margin-left: 5px;
}
</style>
